import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Layout from "../../components/Layout";
import "../../components/ColorModeSwitcher.tsx";
import ColorModeSwitcher from "../../components/ColorModeSwitcher";
export const _frontmatter = {
  "title": "Color Mode Switcher"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Testing Color Modes`}</h1>
    <p>{`Here comes the color switcher:`}</p>
    <ColorModeSwitcher mdxType="ColorModeSwitcher" />
    <p>{`... and here some text afterwards.`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      